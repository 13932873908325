@import '../../assets/css/variables.css';

.sidebar {
}

.sidebar__item .MuiListItem:hover {
  /* background: var(--primary-main); */
  background: black;
}

.MuiListItemButton:hover {
  /* background: var(--primary-main); */
  background: black;
}
