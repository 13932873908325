:root {
  --primary-main: #2da546;
  --primary-light: #caffcd;
  --primary-dark: #007519;
  --secondary-main: #1c2b51;
  --white-main: #ffffff;
  --gray-main: #6e7d8a;
  --gray-light: #f6f6f6;
  --gray-dark: #232d38;
  --black-main: #ffffff;
  --background-light-color: #f5f5f5;
}
