.spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.479);
  display: flex;
  align-items: center;
  justify-content: center;
}
