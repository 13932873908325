@import './variables.css';

html,
body {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  background-color: aqua;
}

.scroll-bar {
  /*
  &::-webkit-scrollbar {
    width: 8px;
    left: 15px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary-main);
    border-radius: 16px;
  }
  */
}
